<template>
  <div class="frais-web-abondance">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select   w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="!isReponsableUbcube">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterType"
            :options="computedListType"
            text-field="text"
            value-field="value"
            @change="handlechangeFilialeSupport"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterType != null">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterStatut"
            :options="[
              { full_name: 'Tous', valus: null },
              ...computedValidationList
            ]"
            text-field="full_name"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <div
          v-if="getLoadingWebAbondanceFrais"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <globalVlidation
            v-if="
              cantCreate(this.$options.name) &&
                getWebAbondanceFraisData &&
                getWebAbondanceFraisData.length > 0 &&
                !isSuperAdminGetter &&
                computedCheckedRows.length &&
                filterType
            "
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @reloadData="reloadData"
            :dataToUse="computedWebAbondanceFraisData"
          />
          <generation-facture-avoir-frais
            :interfaceName="this.$options.name"
            :filterType="filterType"
            :dataToUse="computedWebAbondanceFraisData"
            @reloadData="reloadData"
          />
          <validation-statue-installateur
            v-if="
              cantUpdate(this.$options.name) &&
                filterType &&
                computedCheckedRows.length
            "
            :filterType="filterType"
            :dataToUse="computedWebAbondanceFraisData"
            @reloadData="reloadData"
          />
          <exractionBdd
            curentInterface="Gestion Informatique WA"
            v-if="cantExport(this.$options.name)"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedWebAbondanceFraisData"
            :filter="ComputedFilter"
            :selected="computedCheckedRows.length"
          />
          <div>
            <div
              v-if="getLoadingMail"
              class="chargement chargement-loading-icon"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-if="
                !getLoadingMail &&
                  computedCheckedRows &&
                  computedCheckedRows.length > 0
              "
            >
              <sendMailGestionFrais
                :type="filterType"
                :dataToUse="computedWebAbondanceFraisData"
                v-if="!isSuperAdminGetter && cantSend(this.$options.name)"
              />
            </div>
          </div>
          <exractionBddInvalid
            v-if="cantExport(this.$options.name) && filterType"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedWebAbondanceFraisData"
          />
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisBdd table-header"
          :class="{ customTableWebAbondanceResize: this.filterType == null }"
          :items="computedWebAbondanceFraisData"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          :sort-by.sync="sortBy"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <!-- <template v-if="filterType == null">
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == false
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"/>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == true
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"
              /></template> -->

              <span
                v-if="data.field.key != 'check_all'"
                :class="{
                  'white-color': computedHeaderVisibility(data.field.key)
                }"
              >
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
              v-if="data.item.projects.length != 0"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template>
          <template v-slot:cell(nom)="data">
            <span
              @click="handleDownloadExtraitInstalleur(data.item.nom)"
              :title="'Télécharger extrait ' + data.item.nom"
            >
              {{ data.item.nom }}
            </span>
          </template>
          <template v-slot:cell(dsi)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'dsi'
                })
              "
            >
              {{ data.item.dsi }} €</span
            >
          </template>
          <template v-slot:cell(upcube)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'upcube'
                })
              "
            >
              {{ data.item.upcube }} €</span
            >
          </template>
          <template v-slot:cell(numero_dossier)="">
            <span> -</span>
          </template>
          <template #row-details="data">
            <div v-if="data.item.loadingDetailt">
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </div>
            <span
              v-else
              v-for="(item, index) in data.item.projects"
              :key="data.item.nom + item.nom_pernom + index"
              class="align-td-item border-botom"
            >
              <td class="color">
                <div class="block-service-style">
                  <b-form-checkbox
                    v-model="item.check"
                    @change="checkAllVerifDetails(data.item, item)"
                    class="check-th-details ml-2"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="details-column-frais">
                <div class="block-service-style"></div>
              </td>
              <td class="installeur-column-frais">
                <div class="block-service-style">{{ item.nom_pernom }}</div>
              </td>
              <td class="item-details-style">
                {{ item.numero_dossier }}
              </td>
              <!-- web abondance -->
              <template v-if="!computedFactureVide(item.dsi, item.statue_dsi)">
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('WEB ABONDANCE')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color': item.statue_dsi == 'facture non validée',
                        'green-color': item.statue_dsi == 'paye',
                        'red-color montant-bare': item.statue_dsi == 'annulee'
                      }"
                    >
                      {{ item.dsi }} €
                    </span>
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="WEB ABONDANCE"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_dsi == 'annulee' ? false : item.dsi_download
                      "
                      :id_facture="item.dsi_id"
                      :id_avoir="item.dsi_av_id"
                      :editable="
                        item.statue_dsi == 'paye' ||
                        item.statue_dsi == 'annulee' ||
                        isResposableWebAbondance
                          ? false
                          : true
                      "
                      :sendMailDate="item.dsi_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCretaeLcr({
                        item: item,
                        column: 'dsi'
                      })
                    "
                    champName="dsi"
                    :item="item"
                    :value="item.dsi_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'dsi'
                      })
                    "
                    champName="statue_dsi"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.dsi_id
                    }"
                    :value="item.statue_dsi"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>

                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'dsi'
                        }) && !isResposableWebAbondance
                      "
                      champName="dsi_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.dsi_id
                      }"
                      :value="item.dsi_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component :item="item.dsi_responsable_email" />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  {{ item.dsi_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('WEB ABONDANCE')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'WEB ABONDANCE',
                      key: 'dsi'
                    })
                  "
                >
                  -
                </td>
              </template>
              <!-- UPCUBE -->
              <template
                v-if="!computedFactureVide(item.upcube, item.statue_upcube)"
              >
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('UPCUBE TECHNOLOGIES')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue"
                      :class="{
                        'red-color':
                          item.statue_upcube == 'facture non validée',
                        'green-color': item.statue_upcube == 'paye',
                        'red-color montant-bare':
                          item.statue_upcube == 'annulee'
                      }"
                    >
                      {{ item.upcube }} €</span
                    >
                    <template-frais-vision-filiale
                      :componentName="$options.name"
                      :data="data.item"
                      type="upcube"
                      :client="item"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.statue_upcube == 'annulee'
                          ? false
                          : item.upcube_download
                      "
                      :id_facture="item.upcube_id"
                      :id_avoir="item.upcube_av_id"
                      :editable="
                        item.statue_upcube == 'paye' ||
                        item.statue_upcube == 'annulee'
                          ? false
                          : true
                      "
                      :sendMailDate="item.upcube_email_sent_at"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  <paiement-column-component
                    :editable="
                      computedCretaeLcr({
                        item: item,
                        column: 'upcube'
                      })
                    "
                    champName="upcube"
                    :item="item"
                    :value="item.upcube_type_paiement"
                    :updateFunction="updattypePaiementFrais"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  <EditableInput
                    :editable="
                      computedEditableStatutFrais({
                        item: item,
                        column: 'upcube'
                      })
                    "
                    champName="statue_upcube"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.upcube_id
                    }"
                    :value="item.statue_upcube"
                    :updateFunction="updateStatueFraisVisionFelialeService"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  {{ item.upcube_date }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  {{ item.upcube_numero }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  <div class="block-service-style">
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'upcube'
                        }) && !isResposableWebAbondance
                      "
                      champName="upcube_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.upcube_id
                      }"
                      :value="item.upcube_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    {{ item.upcube_responsable_email }}
                    <icon-info-component
                      :item="item.upcube_responsable_email"
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  {{ item.upcube_email_sent_at }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  {{ item.upcube_lot }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  {{ item.upcube_av_numero }}
                </td>
              </template>
              <template v-else>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="computedShowDetailsColomn('UPCUBE TECHNOLOGIES')"
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-globale"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'UPCUBE TECHNOLOGIES',
                      key: 'upcube'
                    })
                  "
                >
                  -
                </td>
              </template>
            </span>
          </template>
        </b-table>
        <div class=" footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getWebAbondanceFraisDataTotal"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div v-if="computedTotal > 0" class="style-somme-th-ttc">
        <p class="m-2">
          Total ht:
          {{ computedTotal.toFixed(2).toLocaleString('en-US') }}€
          {{ '   ' }}Total ttc:

          {{ computedTotalTTC.toFixed(2).toLocaleString('en-US') }}€
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';
import PaiementColumnComponent from './component/paiementColumnComponent.vue';
export default {
  name: 'Gestion-informatique-web-abondance',
  data() {
    return {
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      filterStatut: null,
      flashMessageId: null,
      sommeSelected: 0,
      filterWeek: null,
      filterDepot: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterFiliale: null,
      filterType: null,
      sortBy: 'nom',
      searchValue: null,
      checkAll: false,
      fields: [
        {
          key: 'check_all',
          label: '',
          views: true,
          tdClass: 'color',
          thClass: 'th-color',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais',
          views: true,
          for: 'all'
        },
        {
          key: 'nom',
          label: 'Filiale Installateur',
          thClass: 'th-installeur-column-frais',
          tdClass: 'installeur-column-frais',
          views: true,
          for: 'all'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          views: true,
          for: 'all'
        },
        {
          key: 'dsi',
          label: 'DSI  (WEB ABONDANCE)',
          views: true,
          details: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'statue_dsi',
          label: 'Statut facture DSI',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_date',
          label: 'Date facture  DSI',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        {
          key: 'dsi_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-dsi',
          for: 'WEB ABONDANCE'
        },
        //
        {
          key: 'upcube',
          label: 'UPCUBE TECHNOLOGIES',
          views: true,
          details: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'statue_upcube',
          label: 'Statut facture UPCUBE',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_date',
          label: 'Date facture UPCUBE',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_dsi_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        },
        {
          key: 'upcube_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-upcube',
          for: 'UPCUBE'
        }
      ],
      listType: [
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'UPCUBE TECHNOLOGIES', text: 'UPCUBE TECHNOLOGIES' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'cantUpdate',
      'cantExport',
      'cantCreate',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'getLoadingWebAbondanceFrais',
      'getWebAbondanceFraisData',
      'isResposableWebAbondance',
      'isSuperAdminGetter',
      'isResponsableFiliale',
      'getErrorWebAbondanceFrais',
      'cantSend',
      'getLoadingMail',
      'isReponsableUbcube',
      'getWebAbondanceFraisDataTotal'
    ]),
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType
      };
    },
    computedEditableStatutInstallateurFrais: function() {
      return function(data) {
        if (data) {
          if (
            this.cantUpdate(this.$options.name) &&
            (this.isResponsableFiliale || this.isSuperAdminGetter) &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true &&
            data.item[data.column + '_statue_installateur'] != 'paye'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedListType() {
      return [{ value: null, text: 'Tous' }, ...this.listType];
    },
    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedWebAbondanceFraisData() {
      return this.getWebAbondanceFraisData;
    },
    computedColumn() {
      switch (this.filterType) {
        case 'WEB ABONDANCE':
          return 'dsi';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        default:
          return this.filterType;
      }
    },
    computedTotal() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedTotalTTC() {
      let some = 0;
      if (
        this.filterType != null &&
        this.filterType === 'UPCUBE TECHNOLOGIES'
      ) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                        .replace(' ', '')
                        .replace(',', '.')
                    ) *
                      1 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      } else {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                        .replace(' ', '')
                        .replace(',', '.')
                    ) *
                      1.2 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedFields() {
      switch (this.filterType) {
        case 'WEB ABONDANCE':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'WEB ABONDANCE'
          );
        case 'UPCUBE TECHNOLOGIES':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'UPCUBE'
          );
        default:
          return this.fields;
      }
      // .filter(item => item.views == true);
    },
    computedHeaderVisibility() {
      return function(data) {
        if (data == 'dsi' || data == 'upcube') {
          return true;
        } else {
          return false;
        }
      };
    },
    computedEditableStatutFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdminGetter) {
            return true;
          }
          if (
            this.cantUpdate(this.$options.name) &&
            !this.isSuperAdminGetter &&
            !this.isResponsableFiliale &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedCretaeLcr: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdminGetter) {
            return true;
          }
          if (
            this.cantCreate(this.$options.name) &&
            !this.isSuperAdminGetter &&
            !this.isResponsableFiliale &&
            data.item['statue_' + data.column] != 'paye' &&
            data.item['statue_' + data.column] != 'annulee' &&
            data.item[data.column + '_download'] == true
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedShowDetailsColomn() {
      return function(data) {
        if (data && data.key) {
          //&& this.fields.find(
          //   item => item.key == data.key && item.details == true
          // ))    ||
          if (this.filterType == null || this.filterType == data.value) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.filterType == null || this.filterType == data) {
            return true;
          } else {
            return false;
          }
        }
      };
    },
    computedFactureVide() {
      return function(montant, statue) {
        if (
          statue == 'facture non validée' &&
          (montant == '0.00' ||
            montant == '0,00' ||
            montant == '0' ||
            montant == 0)
        ) {
          return true;
        }
        return false;
      };
    },
    // computedFactureVideValue() {
    //   return function(item, column, type) {
    //     if (item && column && type) {
    //       let statue = 'statue_' + column;
    //       if (
    //         item[statue] == 'facture non validée' &&
    //         (item[column] == '0.00' || item[column] == '0,00')
    //       ) {
    //         return '-';
    //       }
    //       if (type == 'montant') {
    //         return item[column] + ' €';
    //       } else {
    //         return item[type];
    //       }
    //     }
    //   };
    // },
    computedCheckedRows() {
      return this.getWebAbondanceFraisData?.filter(k => {
        return k.projects.some(e => e.check == true);
      });
    },
    computedType() {
      switch (this.filterType) {
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        default:
          return this.filterType;
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllWebAbondanceFrais',
      'downloadExtraitInstalleurAction',
      'downloadExtraitSupportServiceAction',
      'updateStatueFraisVisionFelialeService',
      'updateStatueInstallateurFraisVisionFelialeService',
      'updateInterface',
      'updattypePaiementFrais',
      'getDataForRowFraisWA'
    ]),
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowFraisWA({
          item: data.item,
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          week: this.filterWeek,
          depot: this.filterDepot,
          statue: this.filterStatut,
          type: this.computedType,
          page: this.page,
          perPage: this.perPage
        });
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    rowClass(item, rowType) {
      if (rowType === 'row-details') {
        return 'subLigneEnCourDeValidation';
      }
      return 'ligneEnCourDeValidation';
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handleChangeDepot() {
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handlechangeFilialeSupport() {
      this.sommeSelected = 0;
      this.filterStatut = null;
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageWebAbondance();
    },
    handleFilter() {
      this.getAllWebAbondanceFrais({
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType,
        page: this.page,
        perPage: this.perPage
      });
      this.setLocalStorageWebAbondance();
    },
    reloadData() {
      this.checkAll = false;
      this.filterStatut = null;
      this.handleFilter();
      this.sommeSelected = 0;
    },
    checkAllFunction() {
      this.getWebAbondanceFraisData.map(item => {
        item.check = this.checkAll;
        item.projects.map(itemD => (itemD.check = this.checkAll));
      });
    },
    checkAllFunctionDetails(data) {
      data.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemD => (itemD.check = item.check));
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    setVisibilityFieldsHeader(key) {
      this.fields.map(item => {
        if (item.key == key) {
          item.details = !item.details;
        }
        if (
          item.key == key + '_type_paiement' ||
          item.key == key + '_date' ||
          item.key == 'statue_' + key ||
          item.key == key + '_numero' ||
          item.key == key + '_statue_installateur' ||
          item.key == key + 'av_numero'
        ) {
          item.views = !item.views;
        }
      });
    },
    handleDownloadExtraitInstalleur(item) {
      if (this.filterMonth != null && this.filterType == null) {
        this.$confirm(
          '',
          'Êtes-vous sur de vouloir télecharger tous les extraits pour ' +
            item +
            ' ?',
          'warning'
        )
          .then(() => {
            this.downloadExtraitInstalleurAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item,
              type: this.typeArray
            });
          })
          .catch(() => {});
      }
    },
    handleDownloadExtraitSupportService(item) {
      if (this.filterMonth != null) {
        this.downloadExtraitSupportServiceAction({
          year: this.filteryear,
          month: this.filterMonth,
          filiale: item.item.nom,
          type: item.type
        });
      }
    },

    setLocalStorageWebAbondance() {
      localStorage.setItem(
        'web-abondance',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          filiale: this.filterFiliale,
          week: this.filterWeek,
          depot: this.filterDepot,
          statue: this.filterStatut,
          type: this.filterType
        })
      );
    },
    checkRole() {
      if (this.isReponsableUbcube) {
        this.filterType = 'UPCUBE TECHNOLOGIES';
      }
    }
  },
  components: {
    EditableInput,
    templateFraisVisionFiliale: () =>
      import('./component/fraisGlobale/templateFraisVisionFiliale.vue'),
    exractionBdd: () => import('./component/fraisGlobale/exractionBdd.vue'),
    sendMailGestionFrais: () =>
      import('./component/fraisGlobale/sendMailGlobalFrais.vue'),
    exractionBddInvalid: () =>
      import('./component/fraisGlobale/exractionBddInvalid.vue'),
    globalVlidation: () =>
      import('./component/fraisGlobale/globalValidation.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    validationStatueInstallateur: () =>
      import('./component/ValidationStatueInstallateur.vue'),
    iconInfoComponent: () => import('./component/iconInfoComponent.vue'),
    generationFactureAvoirFrais: () =>
      import('./component/generationFactureAvoirFrais.vue'),
    PaiementColumnComponent
  },
  async mounted() {
    this.updateInterface('web abondance');
    var courantYear = moment().year();
    this.filteryear = courantYear;
    var courantMonthId = moment().month();
    if (localStorage.getItem('web-abondance')) {
      this.filteryear = JSON.parse(localStorage.getItem('web-abondance')).year;
    }

    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('web-abondance')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('web-abondance')
      ).month;
      this.searchValue = JSON.parse(
        localStorage.getItem('web-abondance')
      ).search;

      this.filterFiliale = JSON.parse(
        localStorage.getItem('web-abondance')
      ).filiale;

      this.filterWeek = JSON.parse(localStorage.getItem('web-abondance')).week;

      this.filterDepot = JSON.parse(
        localStorage.getItem('web-abondance')
      ).depot;

      this.filterStatut = JSON.parse(
        localStorage.getItem('web-abondance')
      ).statue;
      this.filterType = JSON.parse(localStorage.getItem('web-abondance')).type;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageWebAbondance();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.checkRole();
    this.handleFilter();
  },
  watch: {
    getErrorWebAbondanceFrais: function() {
      if (this.getErrorWebAbondanceFrais != null) {
        const objectFlash = {
          blockClass: 'custom-block-class',
          contentClass: 'custom-content-class',
          message: ''
        };
        if (Array.isArray(this.getErrorWebAbondanceFrais)) {
          for (let e in this.getErrorWebAbondanceFrais) {
            objectFlash.message =
              objectFlash.message + ' ' + this.getErrorWebAbondanceFrais[e];
          }
        } else {
          objectFlash.message = this.getErrorWebAbondanceFrais;
        }
        this.flashMessageId = this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          blockClass: objectFlash.blockClass,
          contentClass: objectFlash.contentClass,
          message: objectFlash.message
        });
      } else {
        this.flashMessage.deleteMessage(this.flashMessageId);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.flashMessage.deleteMessage(this.flashMessageId);
    next();
  }
};
</script>

<style scoped lang="scss">
.frais-web-abondance {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableFraisBdd {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
  }
  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .align-icon {
    margin-right: 7px;
    color: #ffff;
    font-size: 10px;
  }

  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
  }
}
</style>

<style lang="scss">
.custom-block-class {
  width: 220px !important;
  z-index: 5;
  p {
    margin-bottom: 0px;
  }
}
.custom-content-class {
  font-size: 10px !important;
}
.color {
  border-left: none !important;
}
.justify-content-sb-frais-globale {
  display: block !important;
  line-height: 11px !important;
}
.footer-total-bg {
  background-color: #dfdef4;
}
.customTableFraisBdd .table.b-table > caption {
  display: table-footer-group !important;
}
.table-rapport-style .customTableFraisBdd td {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}
.table-rapport-style .customTableFraisBdd {
  td,
  th {
    font-size: 9px;
  }
  .btn-anomalie {
    padding: 0px;
  }
}
.table-rapport-style .customTableWebAbondanceResize {
  td,
  th {
    min-width: 100px;
    width: 100px;
  }
}
</style>
